import React,{useState} from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const CancelTicket = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiToken = process.env.REACT_APP_API_TOKEN;
    const [ticketFormData,setTicketFormData] = useState({
        ticketNumber : '',
        mediumOfTicket : '',
        domainName : 'ranchibus.in',
        otp : '',
        refundAmount : 0
    });
    const navigate = useNavigate();
    const [spanTextMsg,setSpanTextMsg] = useState('');
    const [spanTextErrorMsg,setSpanTextErrorMsg] = useState('');
    const [otpFormStatus,setOtpFormStatus] = useState(false);
    const cancelTicket = () => {
        setSpanTextErrorMsg('');
        axios.post(apiUrl+'/booking/cancellation/ticket', ticketFormData, {
            headers: { 
                'YESBUS_API_KEY': apiKey,
                Authorization: `Bearer ${apiToken}`,
            }
        })
        .then((response) => {
            if(response.data.status === '200'){
                setTicketFormData({
                    ticketNumber : '',
                    mediumOfTicket : '',
                    domainName : 'ranchibus.in',
                    otp : '',
                    refundAmount : 0
                });
                setOtpFormStatus(false);
                setSpanTextMsg('');
                alert(response.data.message);
                navigate('/');
            }else{
                setSpanTextErrorMsg(response.data.message);
                // alert(response.data.message);
            }
        })
        .catch((error) => {
            setSpanTextErrorMsg(error.data.message);
            console.error('Error fetching ticket:', error.data.message);
        });
    }
    
    const searchTicketExistance = () => {
        setSpanTextErrorMsg('');
        axios.post(apiUrl+'/booking/cancellation/check-ticket-existance', ticketFormData, {
            headers: { 
                'YESBUS_API_KEY': apiKey,
                Authorization: `Bearer ${apiToken}`,
            }
        })
        .then((response) => {
            if(response.data.status === '200'){
                setTicketFormData({...ticketFormData,refundAmount:response.data.refund_amount});
                setSpanTextMsg(response.data.message);
                setOtpFormStatus(true);
            }else{
                setSpanTextErrorMsg(response.data.message);
                // alert(response.data.message);
            }
        })
        .catch((error) => {
            setSpanTextErrorMsg('Error fetching ticket');
            console.error('Error fetching ticket:', error);
        });
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <link rel="stylesheet" href="/css/contact.css" />
                </Helmet>
            </HelmetProvider>
            <section className="contact_new_sec">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="contact_new_sec_main">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5">
                                        <div className="contact_new_sec_left">
                                            <h6>Do You Want To Cancel Your Ticket ?</h6>
                                        </div>
                                        <div style={{width:'100%'}}>
                                            {
                                                otpFormStatus===true ? (
                                                    <>
                                                        <div className='form-group' style={{marginBottom : '30px'}}>
                                                            <label htmlFor='otp' style={{marginBottom: '10px'}}>Enter OTP</label>
                                                            <input type='text' className='form-control' value={ticketFormData.otp} onChange={(e)=>setTicketFormData({...ticketFormData,otp:e.target.value})} id='otp' placeholder='Enter Your OTP' />
                                                        </div>
                                                        {
                                                            spanTextMsg!='' && 
                                                            <div style={{marginBottom : '30px'}}>
                                                                <span style={{color:'red'}}>{spanTextMsg}</span>
                                                            </div>
                                                        }
                                                        {
                                                            spanTextErrorMsg!='' && 
                                                            <div style={{marginBottom : '10px',fontWeight:'600'}}>
                                                                <span style={{color:'red'}}>{spanTextErrorMsg}</span>
                                                            </div>
                                                        }
                                                        <div style={{textAlign : 'right'}}>
                                                            <button style={{background: 'rgb(45, 49, 141)',color: 'rgb(255, 255, 255)',border:'none', fontWeight:'600',borderRadius: '5px',padding: '7px 25px',fontSize:'14px'}} onClick={cancelTicket}>Cancel Ticket</button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className='form-group' style={{marginBottom : '30px'}}>
                                                            <label htmlFor='ticketNumber' style={{marginBottom: '10px'}}>Ticket Number</label>
                                                            <input type='text' className='form-control' value={ticketFormData.ticketNumber} onChange={(e)=>setTicketFormData({...ticketFormData,ticketNumber:e.target.value})} id='ticketNumber' placeholder='Enter Ticket Number' />
                                                        </div>
                                                        <div className='form-group' style={{marginBottom : '20px'}}>
                                                            <label htmlFor='mediumOfTicket' style={{marginBottom: '10px'}}>Phone Number/Email ID <FontAwesomeIcon icon={faInfoCircle} /></label>
                                                            <input type='text' className='form-control' id='mediumOfTicket' value={ticketFormData.mediumOfTicket} onChange={(e)=>setTicketFormData({...ticketFormData,mediumOfTicket:e.target.value})} placeholder='Enter Phone Number/Email ID which you given on booking time' />
                                                        </div>
                                                        {
                                                            spanTextErrorMsg!='' && 
                                                            <div style={{marginBottom : '10px',fontWeight:'600'}}>
                                                                <span style={{color:'red'}}>{spanTextErrorMsg}</span>
                                                            </div>
                                                        }
                                                        <div style={{textAlign : 'right'}}>
                                                            <button style={{background: 'rgb(45, 49, 141)',color: 'rgb(255, 255, 255)',border:'none', fontWeight:'600',borderRadius: '5px',padding: '7px 25px',fontSize:'14px'}} onClick={searchTicketExistance}>SUBMIT</button>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1"></div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="contact_new_sec_left">
                                            <h6>Connect to <span className="yesbus_contact_span">Ranchi Bus</span> Now!</h6>
                                            <p>Need help booking for seats?</p>
                                            <button type="button">Contact To Us</button>
                                            <span className="contactusor">OR</span>
                                            <h5 className="mb-0">For all other Inquiries</h5>
                                            <h3><span>Email : </span>info@ranchibus.in</h3>
                                            <h3><span>Phone : </span>+91-98-3555-3444</h3>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CancelTicket