import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';


const Login = (props) => {
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [loginFormData, setLoginFormData] = useState({
        userName: '',
        password: '',
        remember : false,
    });
    const [loginError, setLoginError] = useState({
        userName: false,
        password: false,
        remember : false,
        other:false,
        errorMessage: '',
    });
    /* const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginFormData({ ...loginFormData, [name]: value });
    }; */
    const userLogin = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiToken = process.env.REACT_APP_API_TOKEN;
        try {
            const response = await axios.post(apiUrl + '/login', loginFormData, {
            headers: {
                'YESBUS_API_KEY': apiKey,
                Authorization: `Bearer ${apiToken}`,
            }
            });
            console.log(response);
            if (response.data.status === 'success') {
            // Login successful
            localStorage.setItem('yb_user_token', JSON.stringify(response.data.token));
            // Redirect to the login page
            //props.setSignInPopup(false);
            props.setLoginPopup(false);
            }
        } catch (error) {
            setLoginError({ ...loginError, other: true, errorMessage: error.response.data.message });
        } finally {
            setLoginError({ ...loginError, other: true, errorMessage: 'Invalid Credential' });
            setIsLoggingIn(false); // Reset the flag after API request completion
        }
        
    };
    const LoginFormValidation = (e) => {
        e.preventDefault();
        for (let i in loginFormData) {
            if (loginFormData[i] === '') {
                setLoginError({ ...loginError, [i]: true, errorMessage: 'This is required' });
                return false;
            }else{
                setLoginError({ ...loginError, [i]: false, errorMessage: '' });
            }
        }
        setIsLoggingIn(true);
        userLogin();
    }
    //console.log(loginFormData);
    
    return (
        <>
            <LoginSideBar style={props.loginPopup===true && props.signinPopup===false ? {display:''} : {display:'none'}} className='sidebar_sec'>
                <div className='modal-down-bg-sec'></div>
                <div className="modal-login-bg-sec">
                    <div className="login_signup_menu">
                        <div className="login_signup_menu_close new_close_round">
                            <Link onClick={()=>props.setLoginPopup(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                            </Link>
                        </div>
                        <div className='title_head'>
                            <h6>Welcome to RanchiBus!</h6>
                            <h5>Enter your Email/Mobile Number and Password to Login to your account</h5>
                        </div>
                        <div className='login_pass_sec'>
                            {loginError.other===true && <p className="error_message">{loginError.errorMessage}</p>}
                            <form onSubmit={LoginFormValidation}>
                                <div className='form_input_sec'>
                                    <span className="passwrod_sec_inner_cont_input span_of_input">
                                        <FontAwesomeIcon icon={faUser} />
                                        <input type="text" name='userName' value={loginFormData.userName} onChange={(e)=>{setLoginFormData({...loginFormData,userName:e.target.value});setLoginError({...loginError,userName:false})}} placeholder="Enter Your Email/Mobile number"/>
                                    </span>
                                    <span className="input_fld_requierd login-error" style={loginError.userName===true ? {display:''} : {display:'none'}}>{loginError.errorMessage}</span>
                                </div>
                                <div className='form_input_sec'>
                                    <span className="passwrod_sec_inner_cont_input span_of_input">
                                        <FontAwesomeIcon icon={faLock} />
                                        <input type="password" name='password' value={loginFormData.password} onChange={(e)=>{setLoginFormData({...loginFormData,password:e.target.value});setLoginError({...loginError,password:false})}} className="login_password" placeholder="Enter Your Password"/>
                                        {/* <i className="fas fa-eye-slash"></i> */}
                                    </span>
                                    <span className="input_fld_requierd login-error" style={loginError.password===true ? {display:''} : {display:'none'}}>{loginError.errorMessage}</span>
                                </div>
                                <div className="remeber_check_sec">
                                    <div className="new_check_box_sec_new">
                                        <label className="container">Remember Me<input type="checkbox" name='remember' checked={loginFormData.remember}  onChange={(e) => setLoginFormData({ ...loginFormData, remember: e.target.checked })}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <Link onClick={()=>{props.setLoginPopup(false);props.setForgotPasswordPopup(true)}}>Forgot Password?</Link>
                                </div>
                                <div className="login_btns_sec">
                                    <ul>
                                        <li>
                                            <button type="submit" className="login_btn">{isLoggingIn===true ? 'Wait...' : 'Login'}</button>
                                        </li>
                                    </ul>
                                </div>
                                
                                <div className="or_sec_text"><span>OR</span></div>
                                <div className='login_diff_sec'>
                                    <a href="">
                                        <img src="https://i.stack.imgur.com/oL5c2.png" alt="" />
                                    </a>
                                </div>
                                <div className="create_acc_sec">
                                New to RanchiBus? <Link onClick={()=>{props.setLoginPopup(false);props.setSignInPopup(true)}}>Create an account</Link>
                                </div>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </LoginSideBar>
        </>
    )
}
const LoginSideBar = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    ${'' /* background-color: #fff; */}
    z-index: 9999;
    display: grid;
    grid-template-columns: 2fr 1fr;
    .modal-down-bg-sec {
        background: #0000004a;
    }
    .modal-login-bg-sec {
        background: #fff;
        .login_signup_menu{
            padding: 10px 30px 0px 30px;
            .new_close_round{
                font-size: 30px;
                svg{
                    color: #000;
                }
            }
            .title_head {
                h6{
                    text-align: center;
                    color: #2d318d;
                    font-weight: 500;
                    font-size: 22px;
                    margin-bottom: 10px;
                }
                h5 {
                    position: relative;
                    font-size: 27px;
                    font-weight: 300;
                    line-height: inherit;
                    margin-bottom: 30px;
                }
            }
            .login_pass_sec{
                form{
                    .form_input_sec{
                        padding-top: 20px;
                        .span_of_input{
                            position: relative;
                            .login_password{
                                margin-top: 20px;
                            }
                            svg{
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 1;
                                color: #2d318d;
                                font-size: 22px;
                                border-right: 1.5px solid #ccc;
                                padding-right: 15px;
                            }
                            input{
                                position: relative;
                                width: 100%;
                                height: 42px;
                                border: none;
                                border-bottom: 1.5px solid #2d318d;
                                color: #000000;
                                font-size: 18px;
                                font-weight: 400;
                                padding-left: 50px !important;
                            }

                        }
                        .input_fld_requierd {
                            position: absolute;
                            color: #FF4E4E;
                            font-size: 15px;
                            font-weight: 400;
                            text-align: center;
                            background: #FFFFFF 0% 0% no-repeat padding-box;
                            box-shadow: 0px 1px 10px #00000029;
                            border: 1px solid #FFB0B0;
                            height: 40px;
                            padding: 0 15px;
                            left: auto;
                            right: auto;
                            z-index: 11;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: auto;
                            top: auto;
                        }
                        .input_fld_requierd:after {
                            content: '';
                            position: absolute;
                            right: 0;
                            left: 50%;
                            top: -9px;
                            font-family: FontAwesome;
                            height: 15px;
                            width: 15px;
                            background: #fff;
                            border-top: 1px solid #ffb0b0;
                            border-left: 1px solid #ffb0b0;
                            transform: rotate(45deg);
                            z-index: -11;
                        }
                    }
                    .remeber_check_sec {
                        position: relative;
                        margin: 18px 0 0 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .container {
                            display: block;
                            position: relative;
                            padding-left: 30px;
                            margin-bottom: 0px;
                            cursor: pointer;
                            font-size: 18px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            font-weight: 400;
                            color: #000;
                            input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                                height: 0;
                                width: 0;
                            }
                            .checkmark {
                                position: absolute;
                                top: 3px;
                                left: 0;
                                height: 18px;
                                width: 18px;
                                background-color: transparent;
                                border: 1px solid #000;
                                border-radius: 4px;
                            }
                        }
                    }
                    .login_btns_sec{
                        padding-top: 30px;
                        text-align: center;
                        .login_btn{
                            display: inline-block;
                            font-weight: 600;
                            text-transform: uppercase;
                            position: relative;
                            background: rgb(45, 49, 141);
                            -webkit-box-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            justify-content: center;
                            color: rgb(255, 255, 255);
                            border-radius: 5px;
                            padding: 7px 25px;
                            font-size: 14px;
                            transition: all 0.5s ease-in-out 0s;
                            border: 1.5px solid rgb(45, 49, 141);
                        }
                        .login_btn:hover{
                            background: #fff;
                            color: rgb(45, 49, 141);
                        }
                    }
                    .or_sec_text {
                        position: relative;
                        padding-left: 0px;
                        margin: 30px 0 0px 0;
                        text-align: center;
                        span {
                            font-size: 20px;
                            font-weight: 500;
                            color: #8b8b8b;
                            margin: 10px 0 15px 0;
                            position: relative;
                        }
                        span:before {
                            position: absolute;
                            content: '';
                            right: 40px;
                            top: 13px;
                            height: 1.5px;
                            width: 80px;
                            background: #8b8b8b;
                        }
                        span:after {
                            position: absolute;
                            content: '';
                            left: 40px;
                            top: 13px;
                            height: 1.5px;
                            width: 80px;
                            background: #8b8b8b;
                        }
                    }
                    .login_diff_sec{
                        text-align: center;
                        padding-top: 20px;
                        img{
                            width: 50%;
                        }
                    }
                    .create_acc_sec{
                        padding-top: 40px;
                        font-size: 18px;
                        text-align: center;
                        a{
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 767px) { 
        grid-template-columns: 1fr;
        
    }
    @media (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr 2fr;
    }
`;

export default Login