import React from 'react';
import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import Modal from 'react-modal';
import styled from 'styled-components'


const DatePickerModal = (props) => {
    Modal.setAppElement('#root');
    return (
        <>
            <Modal 
                isOpen={props.showmodal===true ? true : false}
                onRequestClose={()=>{document.body.classList.remove('body-no-scroll');}}
                contentLabel="Datepicker Modal"
                style={customStyles}
                shouldCloseOnOverlayClick={false}
            >
                <DatePickerDiv>
                    <div className='date_picker_close_div'>
                        <button type="button" className="close" data-dismiss="modal" onClick={()=>{document.body.classList.remove('body-no-scroll');props.modalStatus(false);}}>
                            <img src={process.env.PUBLIC_URL + '/images/Close_red_btn.svg'} alt="ranchibus" />
                        </button>
                    </div>
                    <div className='datepicker_place_main_div'>
                        <DatePickerCalendar 
                            locale={enGB} 
                            minimumDate={new Date()}
                            // format='eee, d MMM, yyyy'
                            onDateChange={props.chooseDate}
                            date={props.selectedDate}
                        />
                    </div>
                </DatePickerDiv>
            </Modal>
        </>
    )
}
const screenWidth = window.innerWidth;
let widthSet = '90%';
if (screenWidth < 600) {
    widthSet = '90%';
} else if (screenWidth < 1024) {
    widthSet = '60%';
} else {
    widthSet = '50%';
}
const customStyles = {
    overlay: {
        backgroundColor: 'rgb(2 2 5 / 59%)', // Adjust the color and opacity as needed
        zIndex: '9999',
    },
    content: {
        top: '50%',          // Center the modal vertically
        left: '50%',         // Center the modal horizontally
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%', // Adjust based on modal width
        transform: 'translate(-50%, -50%)', // Center the modal both horizontally and vertically
        width: widthSet,        // Adjust the modal width as needed
        backgroundColor: 'white',
        border: '1px solid #ccc',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        padding: '0px',
        overflow: 'inherit',
        // height: '70vh'
    },
};

const DatePickerDiv = styled.div`
    .date_picker_close_div{
        text-align: right;
        button{
            border: none;
            background: no-repeat;
            padding: 12px 5px;
            width: 35px;
        }
    }
    .datepicker_place_main_div{
        margin: 0px 10px 10px;
        border: 1px solid rgb(46, 49, 136);
        border-radius: 5px;
        .nice-dates-navigation{
            border-bottom: 1px solid rgb(46, 49, 136);
        }
        .nice-dates-navigation_current{
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            color: rgb(46, 49, 136);
        }
        .nice-dates-navigation_previous:before, .nice-dates-navigation_next:before{
            border-right: 3px solid rgb(46, 49, 136);
            border-top: 3px solid rgb(46, 49, 136);
        }
        .nice-dates-week-header{
            box-shadow: none;
            padding: 8px 4px;
        }
        .nice-dates-week-header_day{
            background: rgb(46, 49, 136);
            padding: 9px 5px;
            margin-right: 3px;
            border-radius: 5px;
            color: rgb(200, 71, 59);
            font-weight: 700;
        }
        .nice-dates-day_month{
            color: rgb(200, 71, 59);
            font-size:10px;
            font-weight: 500;
        }
        .nice-dates-week-header .nice-dates-week-header_day:last-child{
            margin-right:0px;
        }
        .nice-dates-grid{
            margin: 10px 5px;
        }
        .nice-dates-day{
            box-shadow: 1px 2px 8px #b2b2b2;
            background: #fcfcfc;
            order: none;
            color: rgb(46, 49, 136);
        }
        .nice-dates-day.-outside{
            color: rgb(46, 49, 136);
        }
        .nice-dates-day.-today{
            background: #2f3183 !important;
            color: #fff !important;
            border: 2px solid rgb(200, 71, 59);
        }
        .nice-dates-day.-disabled{
            color: #adadad;
        }
        .nice-dates-navigation_previous.-disabled:before, .nice-dates-navigation_next.-disabled:before{
            border-right: 3px solid #aeaeae;
            border-top: 3px solid #aeaeae;
        }
        .nice-dates-day.-selected:before{
            background: none;
        }
        .nice-dates-day.-selected:not(:hover), .nice-dates-day.-selected.-disabled{
            background: rgb(200, 71, 59);
            border: 2px solid rgb(46, 49, 136);
        }
    }
`;
export default DatePickerModal