import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TicketDetails from './TicketDetails';
import html2pdf from 'html2pdf.js';
import axios from 'axios';

const Ticket = () => {
  /* const saveAsPdf = () => {
    const element = document.getElementById('pdf-container'); // ID of the div to be converted to PDF
    const opt = {
      margin: 10,
      filename: 'ticket.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
      .from(element)
      .set(opt)
      .save();
  } */
  const saveAsPdf = () => {
    const element = document.getElementById('pdf-container'); // ID of the div to be converted to PDF
    const temporaryClass = 'pdf-generating';
    element.classList.add(temporaryClass);
    const opt = {
      margin: 10,
      filename: 'ticket.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
    .from(element)
    .set(opt)
    .save()
    .then(() => {
      // After PDF generation is complete, remove the temporary class
      element.classList.remove(temporaryClass);
    });
  }
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiToken = process.env.REACT_APP_API_TOKEN;
  const [ticketDetails, setTicketDetails] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const ticketNumber = location.pathname.replace('/ticket/', '');
  useEffect(() => {
    axios.get(apiUrl+'/booking/ticket/'+ticketNumber, { 
      headers: {
        'YESBUS_API_KEY': apiKey,
        Authorization: `Bearer ${apiToken}`,
      }
    })
    .then((response) => {
      if(response.data.status===200){
        setTicketDetails(response.data.data);
      }else{
        setTicketDetails([]);
        //navigate('/');
      }
    })
    .catch((error) => {
      console.log(error);
      setTicketDetails([]);
      //navigate('/');
    });
  }, []);
  return (
    <>
      <TicketContainer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 desk_mode">
              <div className="save-ticket-div no-print">
                <Link onClick={() => window.print()}>
                  <img src="/images/print.png" alt="ranchibus" />
                </Link>

                <Link onClick={saveAsPdf}>
                  <img src="/images/download-pdf-icon.webp" alt="ranchibus" />
                </Link>

               {/*  <PDFDownloadLink onClick={handleDownloadPDF}>
                  <img src="https://ranchibus.in/images/pdf-icon.jpg" alt="ranchibus" />
                </PDFDownloadLink> */}
              </div>
              <TicketDetails ticketData={ticketDetails} />
            </div>
            
          </div>
        </div>
      </TicketContainer>
      <div className='mob_ticket_details_sec mob_mode'>
        <h6>
          Please 
          <Link onClick={saveAsPdf}>
            click here 
          </Link>
          to download your ticket.
        </h6>
      </div>
    </>
  )
}
const TicketContainer = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  .save-ticket-div{
    width: 100%;
    text-align: right;
    padding: 10px 0px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    gap: 30px;
    img{
      width: 50px;
      cursor: pointer;
    }
  }
  
  @media(max-width: 767px){
    .ticket{
      .ticket-body{
        flex-direction: column;
        .ticket-body-left{
          width: 100%;
          margin-bottom: 20px;
        }
        .ticket-body-right{
          width: 100%;
        }
      }
    }
  }
  @media(max-width: 575px){
    .ticket{
      .ticket-body{
        .ticket-body-left{
          .ticket-body-left-row{
            flex-direction: column;
            .ticket-body-left-row-left{
              width: 100%;
              margin-bottom: 10px;
            }
            .ticket-body-left-row-right{
              width: 100%;
            }
          }
        }
        .ticket-body-right{
          .ticket-body-right-row{
            flex-direction: column;
            .ticket-body-right-row-left{
              width: 100%;
              margin-bottom: 10px;
            }
            .ticket-body-right-row-right{
              width: 100%;
            }
          }
        }
      }
    }
  }
  @media(max-width: 400px){
    .ticket{
      .ticket-body{
        .ticket-body-left{
          .ticket-body-left-row{
            .ticket-body-left-row-left{
              p{
                font-size: 12px;
              }
            }
            .ticket-body-left-row-right{
              p{
                font-size: 12px;
              }
            }
          }
        }
        .ticket-body-right{
          .ticket-body-right-row{
            .ticket-body-right-row-left{
              p{
                font-size: 12px;
              }
            }
            .ticket-body-right-row-right{
              p{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  @media(max-width: 350px){
    .ticket{
      .ticket-body{
        .ticket-body-left{
          .ticket-body-left-row{
            .ticket-body-left-row-left{
              p{
                font-size: 10px;
              }
            }
            .ticket-body-left-row-right{
              p{
                font-size: 10px;
              }
            }
          }
        }
        .ticket-body-right{
          .ticket-body-right-row{
            .ticket-body-right-row-left{
              p{
                font-size: 10px;
              }
            }
            .ticket-body-right-row-right{
              p{
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
  @media(max-width: 300px){
    .ticket{
      .ticket-body{
        .ticket-body-left{
          .ticket-body-left-row{
            .ticket-body-left-row-left{
              p{
                font-size: 8px;
              }
            }
            .ticket-body-left-row-right{
              p{
                font-size: 8px;
              }
            }
          }
        }
        .ticket-body-right{
          .ticket-body-right-row{
            .ticket-body-right-row-left{
              p{
                font-size: 8px;
              }
            }
            .ticket-body-right-row-right{
              p{
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }
  @media(max-width: 250px){
    .ticket{
      .ticket-body{
        .ticket-body-left{
          .ticket-body-left-row{
            .ticket-body-left-row-left{
              p{
                font-size: 6px;
              }
            }
            .ticket-body-left-row-right{
              p{
                font-size: 6px;
              }
            }
          }
        }
        .ticket-body-right{
          .ticket-body-right-row{
            .ticket-body-right-row-left{
              p{
                font-size: 6px;
              }
            }
            .ticket-body-right-row-right{
              p{
                font-size: 6px;
              }
            }
          }
        }
      }
    }
  }
  @media(max-width: 200px){
    .ticket{
      .ticket-body{
        .ticket-body-left{
          .ticket-body-left-row{
            .ticket-body-left-row-left{
              p{
                font-size: 4px;
              }
            }
            .ticket-body-left-row-right{
              p{
                font-size: 4px;
              }
            }
          }
        }
        .ticket-body-right{
          .ticket-body-right-row{
            .ticket-body-right-row-left{
              p{
                font-size: 4px;
              }
            }
            .ticket-body-right-row-right{
              p{
                font-size: 4px;
              }
            }
          }
        }
      }
    }
  }
  @media print {
    /* Hide the header and footer when printing */
    @page {
      size: auto;
      margin: 0;
    }

    body {
      margin: 0;
    }
    .no-print {
      display: none;
    }

    /* Add more styles to hide other elements if needed */
  }
`;
export default Ticket