import React,{useEffect} from 'react'

import HomeBanner from './home/HomeBanner'
import Addrun from './home/Addrun'
import CustomerRatings from './home/CustomerRatings';
import TopRoutes from './home/TopRoutes';
import TopOperators from './home/TopOperators';
const Home = () => {
  useEffect(()=>{
    localStorage.removeItem('bookingDetails');
  },[]);
  return (
    <>
      <HomeBanner/>
      <Addrun/>
      <div className="home_content">
        <section className="section-about">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="about_us_sec_main">
                  <div className="about-img">
                    <img src="/images/passengers-waiting-for-bus.jpg" />
                  </div>
                  <div className="section-about-right fadeInRight">
                    <h3><span>We are the Most Trusted & Safest Online Bus Booking Platform</span></h3>
                    <p >Jharkhand's first ever online bus ticket booking platform that aims to offer a great level of convenience and reliability. With RANCHIBUS, you will be able to get rid of the long queues on bus stations or jammed streets to book the bus tickets. We are the most reliable online portal, where you will be able to book bus tickets directly from the major bus operators (over 1000 bus operators) for all the routes in Jharkhand and surrounding States.</p>
                    <a href="/" className="border-btn bh">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="creater_available_sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="creater_available_sec_inner">
                  <div className="creater_available_sec_inner_single">
                    <div className="creater_available_sec_inner_single_img">
                      <img src="/images/banner25122020-766.png" alt="" />
                    </div>
                    <div className="creater_available_sec_inner_single_inner">
                      <h6>Lowest Bus Prices</h6>
                      <p>Fare Guarantee</p>
                    </div>
                  </div>
                  <div className="creater_available_sec_inner_single">
                    <div className="creater_available_sec_inner_single_img">
                      <img src="/images/banner25122020-150.png" alt=""/>
                    </div>
                    <div className="creater_available_sec_inner_single_inner">
                      <h6>Easy Book in Just 3 Steps</h6>
                      <p>With Instant confiramation</p>
                    </div>
                  </div>
                  <div className="creater_available_sec_inner_single">
                    <div className="creater_available_sec_inner_single_img">
                      <img src="/images/banner25122020-413.png" alt=""/>
                    </div>
                    <div className="creater_available_sec_inner_single_inner">
                      <h6>24x365 Days</h6>
                      <p>Free Phone Assistance</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our_popular_feature_sec *desk_mode">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="featured_jobs_sec_head">
                <h2>WHAT MAKES US UNIQUE</h2>
                <p>"RanchiBus is the largest online bus ticketing platform of Jharkhand,trusted by over 6 millions Indians. With a sale of over 10,00,00,000 Bus tickets via web,mobile,and our bus agents,it stands at the top of the game in bus ticketing. RanchiBus operates on over 84000 routes and is associated with 2300 reputed bus operators. Try the best bus experience today."</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="our_popular_feature_sec_body_sec">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="popular_fea_sec_body new_single_bg_sec">
                      <div className="popular_fea_sec_single">
                        <img src="/images/banner25122020-992.png" alt='' />
                        <div className="popular_fea_sec_single_cont">
                          <h6>RanchiBus</h6>
                          <p>Get a little extra! Additional discounts sponsored by your favorite bus operators exclusively on RanchiBus</p>
                        </div>
                      </div>
                      <div className="popular_fea_sec_single">
                        <img src="/images/banner25122020-211.png" alt='' />
                        <div className="popular_fea_sec_single_cont">
                          <h6>RATINGS AND PICTURE REVIEWS</h6>
                          <p>Rely on ratings and actual photos from fellow travellers to choose your bus!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="popular_fea_sec_body new_single_bg_sec">
                      <div className="popular_fea_sec_single">
                        <img src="/images/banner25122020-753.png" alt='' />
                        <div className="popular_fea_sec_single_cont">
                          <h6>REST STOPS</h6>
                          <p>Get information on rest stops along the way.</p>
                        </div>
                      </div>
                      <div className="popular_fea_sec_single">
                        <img src="/images/banner25122020-658.png" alt='' />
                        <div className="popular_fea_sec_single_cont">
                          <h6>LIVE TRACKING</h6>
                          <p>Track your bus with our Bus Tracking feature and know the exact location in real-time.Stay informed and keep your family informed!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="why-us">
          <div className="container-fluid">
            <div className="row why-us-box-main">
              <div className="col-md-6 col-lg-3">
                <div className="why-us-box services">
                  <div className="why-img">
                    <img src="/images/banner25122020-352.png" alt='' />
                  </div>
                  <h3>MAXIMUM CHOICE</h3>
                  <p>We give you the widest number of travel options across thousands of routes.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="why-us-box services">
                  <div className="why-img">
                    <img src="/images/banner25122020-543.png" alt='' />
                  </div>
                  <h3>SUPERIOR CUSTOMER SERVICE</h3>
                  <p>We put our experience and relationships to good use and are available to solve your travel issues.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="why-us-box services">
                  <div className="why-img">
                    <img src="/images/banner25122020-664.png" alt='' />
                  </div>
                  <h3>LOWEST PRICES</h3>
                  <p>We always give you the lowest price with the best partner offers.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="why-us-box services">
                  <div className="why-img">
                    <img src="/images/banner25122020-302.png" alt='' />
                  </div>
                  <h3>UNMATCHED BENEFITS</h3>
                  <p>We take care of your travel beyond ticketing by providing you with innovative and unique benefits.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='app'>
          <div className="container-fluid">
            <div className="row">
              <div className="find_best_deal_sec_main">
                <div className="section-about-right">
                  <h3>FIND BEST DEAL, DON'T OVERPAY! </h3>
                  <p>Get multiple discounts on each booking.</p>
                  <p >We get our best fares directly from operators and top travel sites. Take advantage and fullfil your travel needs by using Trabol. Trabol compares and finds the best bus deal for your Travel.</p>
                  <div className="cf counter_wrap row-centered">
                    <div className="text-center col-centered">
                      <img className="img-responsive center-block" src="/images/routes.png" alt='' />
                      <h2><span className="counter">80,000</span>+</h2>
                      <h5>Routes</h5>
                    </div>
                    <div className="text-center col-centered">
                      <img className="img-responsive center-block" src="/images/bus4.png" alt='' />
                      <h2><span className="counter">1800</span>+</h2>
                      <h5>Partners</h5>
                    </div>
                    <div className="text-center col-centered">
                      <img className="img-responsive center-block" src="/images/men.png" alt='' />
                      <h2><span className="counter">400,000</span>+</h2>
                      <h5>Customers</h5>
                    </div>
                  </div>
                </div>
                <div className="about-img desk_mode">
                  <img src="/images/mobi_laptop.jpg" alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* <section className="app">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="app-left">
                  <h3 className="title-section">Drive yourself <br />through the mobile</h3>
                  <img src="../images/app-down.png" alt='' />
                  <p>Get SMS with link to download RanchiBus App</p>
                </div>
                <form noValidate="">
                  <div className="row">
                    <div className="col-md-6 pr-0">
                      <input type="text" maxLength="10" id="phone" placeholder="Enter Mobile No." />
                    </div>
                    <div className="col-md-3 pl-0">
                      <button type="submit" className="btn">Get Link</button>
                    </div>
                  </div>
                  <div className="row"></div>
                </form>
              </div>
              <div className="col-md-6">
                <div className="app-right">
                  <img src="https://ranchibus.in/images/mobile_screen.png" alt='' />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <TopRoutes/>
        <TopOperators/> */}
        {/* <CustomerRatings/> */}
      </div>
    </>
  )
}

export default Home